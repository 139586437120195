import React from "react"
import { Link } from "gatsby"

const Header = () => (
	<div id="header">
        <div className="id">
            <span className="id-name">Scott<br /> Luxford</span>
		    <span className="id-title">Designer +<br /> Developer</span>
            <span className="id-areas">UI / UX / Frontend / Growth</span>
        </div>
        <Link className="header-home" to="/"><span className="sr-only">Home</span></Link>
    </div>
)

export default Header
