import React from "react"

import { Helmet } from 'react-helmet'

import { Link } from "gatsby"

import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

import IconAstronaut from "../images/icons/user-astronaut.svg"

const gravatar = 'https://www.gravatar.com/avatar/9a8805f0ba0d71d68f651533a404226d?s=600';

const AboutPage = () => (
  <>
    <Seo title="About me - Scott Luxford" image={gravatar} />
    <Helmet>
      <body className="projects" />
    </Helmet>
    <Header />
    <main className="project" id="top">
        <article>
            <h1 className="heading-offset"><img role="presentation" src={IconAstronaut} /> About me</h1>

            <section className="section section-text-first section-media-narrow">

                    <div className="section-text">
                        <p>I'm a UI/UX Designer and Developer interested in pushing the envelope in usability, aesthetics and technology through <strong>human centred design</strong> and standards based development.</p>

                        <p>My <strong>philosophy</strong> when creating digital experiences is to achieve a design and build that's simple, intuitive, inclusive and accessible - with a touch of cleverness and charm.</p>

                        <p>Across my career I've worn many hats covering UX design, product design, UI design, frontend development, and full stack development. I love iterating end to end from UX research and UI concepts through to build, test and deployment.</p>

                        <p>I'm comfortable working independently, collaboratively or leading a small team, and I'm always keen to share my knowledge and experience. I'm a big advocate for agile and open ways of working; design sprints, growth hacking and hackathons.</p>

                        <p>My project experience runs a wide gamut; designing and developing design systems, CMS websites for agencies, large applications for business and finance, as well as producing advertising, print, animation, video and multimedia across desktop and mobile platforms.</p>

                        <p>Take a look at my <Link to="/projects">portfolio</Link> for a peek at what I do.</p>
                    </div>

                    <div className="section-media">
                        <img src={gravatar} alt="Scott Luxford" />
                    </div>

                </section>

        </article>
    </main>
    <Footer />
  </>
)

  export default AboutPage
